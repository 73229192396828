import api from "./api.service";
import { Product } from "../types";

type Request = {
  id?: number;
  search?: string;
  limit?: number;
  page?: number;
};

const all = async ({
  id = 0,
  search = "",
  limit = 50,
  page = 1,
}: Request): Promise<Product[]> => {
  const query = `/products?id=${id}&search=${search}&limit=${limit}&page=${page}`;
  const { data } = await api.get<Product[]>(query);
  return parseProducts(data);
};

const parseProducts = (products: Product[]) => {
  return products?.map((product) => ({
    ...product,
    garantia_estendida: JSON.parse(
      (product?.garantia_estendida as unknown as string) || "[]"
    ),
    img: JSON.parse((product?.img as unknown as string) || "[]").reduce(
      (ac, productImg) => {
        const listImg = productImg?.split(",") || [];

        return [...ac, ...listImg];
      },
      []
    ),
  }));
};

export default {
  all,
};
