import { Notification } from "rsuite";
import api from "./api.service";
import { User, loginRequest } from "../types";
const login = async ({
  username,
  password,
}: loginRequest): Promise<User | null> => {
  return api
    .post("/sessions", {
      username,
      password,
    })
    .then(({ data }) => {
      localStorage.setItem("@feirao:user", JSON.stringify(data));
      return data;
    })
    .catch((err) => {
      const message = err?.response?.data?.message;
      Notification.warning({
        title: "Falha na autenticação",
        description: message,
      });
    });
};

const updateUser = (user: User): User => {
  localStorage.setItem("@feirao:user", JSON.stringify(user));

  return user;
};

const logout = () => {
  localStorage.removeItem("@feirao:user");
};

const getCurrentUser = (): User | null => {
  const userLoged = localStorage.getItem("@feirao:user");
  const user = userLoged ? JSON.parse(userLoged) : null;

  return user;
};

export default {
  login,
  logout,
  getCurrentUser,
  updateUser,
};
